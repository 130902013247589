.header {
    text-align: center;
  }

.navbar-expand .navbar-nav .nav-link {
    padding-left: 0px;
}  

.welcomeMargin {
    margin-top: 5rem;
}

.bodyMargin{
    margin-top: 10rem;
}

/* From http://tobiasahlin.com/spinkit/ */
.spinner {
    width: 40px;
    height: 40px;
    background-color: blue;
    margin: 0px 0px 15px 0px;
    -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
    animation: sk-rotateplane 1.2s infinite ease-in-out;
  }
  
  @-webkit-keyframes sk-rotateplane {
    0% { -webkit-transform: perspective(120px) }
    50% { -webkit-transform: perspective(120px) rotateY(180deg) }
    100% { -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg) }
  }
  
  @keyframes sk-rotateplane {
    0% {
      transform: perspective(120px) rotateX(0deg) rotateY(0deg);
      -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
    } 50% {
      transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
      -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
    } 100% {
      transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
      -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
  }
  
  @import '~react-toastify/dist/ReactToastify.css';
  